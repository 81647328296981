@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Poppins', sans-serif !important;
    font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-button-next:after {
  color: whitesmoke !important;
  font-size: 20px !important;
  font-weight: bolder;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3d3d3d;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 767px) {
  .block {
    display: block !important;
  }

  .hidden {
    display: none !important;
  }
}

.relative:hover .hidden {
  display: block !important;
}

/* .mySwiper {
  width: 100%;
  height: 100%;
} */

.swiper-slide {
  text-align: center;
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
  object-position: center;
  /* Center slide text vertically */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}

.swiper-slide img {
  /* display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; */
  object-position: center !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* text-shadow: 5px 5px 10px gray; */
}


.container-1::after,
.container-2::after,
.container-3::after {

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f0f0fde;
  position: absolute;
  color: #ffffff;
  height: 16rem;
  width: 73%;
  border-radius: 100%;
  z-index: 5;
  padding: 1.25rem;
  font-size: 14px;
  font-weight: 700;
  transform: scale(0);
  transition: transform ease-out 350ms, border-radius ease-out 500ms;
}

.container-1::after {
  content: 'Tech Core LLC excels in creating novel innovations that redefine industries and elevate consumer experiences.';
}

.container-2::after {
  content: 'Tech Core LLC specializes in offering cutting-edge smoke-related products that redefine user experiences, setting new standards in the market.';
}

.container-3::after {
  content: 'Tech Core Distribution LLC goes the extra mile by assisting customers to excel and achieve their best sales performance';
}

.container-1:hover::after,
.container-2:hover::after,
.container-3:hover::after {
  transform: scale(1);
  border-radius: 10px;
}



.container-1::before,
.container-2::before,
.container-3::before {

  position: absolute;
  width: 74%;
  z-index: 4;
  height: 16rem;
  display: grid;
  place-items: center;
  font-size: 1.3rem;

  text-transform: uppercase;
  color: white;
  -webkit-text-stroke: 0%;
  letter-spacing: 1px;
  border-radius: 10px;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.3);
  text-align: center;
  transition-delay: 400ms;
}

.container-1::before {
  background-color: #492020bd;
  content: 'Novelty Creation';
}

.container-2::before {
  background-color: #202122bd;
  content: 'Delivering Unparalleled Experiences';
}

.container-3::before {
  background-color: #173846bd;
  content: 'Empowering Your Sales Success';
}

.container-1>p,
.container-2>p,
.container-3>p {
  text-align: center;
  text-shadow: 2px 2px 10px rgba(245, 245, 245, 0.404);
}

@media (min-width: 1024px) {

  .container-1::after,
  .container-2::after,
  .container-3::after,
  .container-1::before,
  .container-2::before,
  .container-3::before {
    width: 24.5%;
  }

  .container-1::before,
  .container-2::before,
  .container-3::before {
    -webkit-text-stroke: 1px;
    font-size: 1.6rem;
  }
}

.slick-prev:before,
.slick-next:before {
  color: rgba(43, 42, 42, 0.822) !important;
  font-size: 15px !important;
}

.product-page {
  /* background: hsla(0, 0%, 100%, 1);

  background: linear-gradient(135deg, hsla(0, 0%, 100%, 1) 60%, hsla(0, 66%, 58%, 1) 60%);
  
  background: -moz-linear-gradient(135deg, hsla(0, 0%, 100%, 1) 60%, hsla(0, 66%, 58%, 1) 60%);
  
  background: -webkit-linear-gradient(135deg, hsla(0, 0%, 100%, 1) 60%, hsla(0, 66%, 58%, 1) 60%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FFFFFF", endColorstr="#DA4D4D", GradientType=1 ); */
}

textarea {
  resize: none;
}

input:active,
input:focus,
textarea:active,
textarea:focus {
  border: none !important;
  outline: none !important;
  box-shadow: 0px 0px 5px gray;
}

::placeholder {
  font-size: 15px;
  font-weight: bold;

}

input:checked {
  background-color: black;
}

.product-card-div:hover>.product-card-name-div {
  background-color: rgba(189, 40, 40, 0.884);
}

.homepage-section,
.products-page,
.contact-section,.product-detail-page {
  background-image: url('/public/assets/vape-doodle-2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-detail-page{
  text-shadow: 0px 0px 5px rgba(128, 128, 128, 0.404);
}
/* .homepage-section::before, .products-page::before, .contact-section::before{
  content: '';
  position: absolute;
  width: 100%;
  background-color: #ffffffd8;
  z-index: 0;
}

.homepage-section::before{
  height: 290vh;
}
.products-page::before{
  min-height: 100% ;
}
.contact-section::before{
  height: 100vh;
} */
.footer {
  position: relative;
  z-index: 100;
}

.hidden-css {
  opacity: 0 !important;
  transition: all 1s ease-in-out;
  transform: translateY(-40%);
  filter: blur(5px);
}

.show-css {
  opacity: 1 !important;
  transform: translateY(0);
  filter: blur(0px);
}

